import moment from 'moment';

export const getMonthsInCSTFormat = (date: any, months: any) => {
    date.setMonth(date.getMonth() + months);
    const year = date.getFullYear();
    let month = date.getMonth() + 1; // Months start at 0!
    let day = date.getDate();
    let endDate = `${year}-${month}-${day}`;
    return endDate;
}

export const getDateFormat = (dateString: string) => {
    return moment(dateString).format('YYYY-MM-DD')
}
