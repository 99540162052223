import {AccordionPanel} from "@panwds/react-ui";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm} from "react-final-form";
import {PANWDSRadioField, PANWDSSelectWithSearch} from "../../../../components/PANWDSElements";
import {isRequired} from "../../../../utils/validate";
import {Row} from "../../../../components/FormElements";
import * as React from "react";
import {useTranslate} from "../../../../customHooks";
import {useEffect, useState} from "react";
import {dataProvider} from "../../../../dataProvider";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import {toast} from "../../../../components";
import { OnChange } from "react-final-form-listeners";

const Index = () => {
    const form = useForm();
    const translate = useTranslate();
    const [ruleStackChoices, setRuleStackChoices] = useState<any[] | undefined>(undefined);
    const [panoramas, setPanoramas] = useState<any[] | undefined>(undefined);
    const [cloudManagers, setCloudManagers] = useState<any[] | undefined>(undefined);

    useEffect(() => {

        dataProvider.get("ruleStacks", {
            data: {
                Candidate: false,
                Running: true,
                Scope: "Local"
            }
        }).then(async (response: DataTypes.IFwaasApiResponse) => {
            //@ts-ignore
            let ret: DataTypes.IFwaasApiResponse = {};
            if (response?.data) {
                //@ts-ignore
                let rulestacks = response.data as string[];
                if (rulestacks) {
                    let choices: any = rulestacks.map((rulestack: any) => ({text: rulestack, value: rulestack}));
                    choices = choices.sort((first: any, second: any) => {
                        return first.value.localeCompare(second.value, undefined, {sensitivity: 'base'});
                    });

                    setRuleStackChoices(choices);
                    return;
                }
            } else {
                toast.error(response?.error, {toastId: "ruleStacks-get"});
            }
            return ret;
        }).catch((e: any) => {
            toast.error(e?.error, {toastId: "ruleStacks-get"});
        });

        let apiCall: { resource: string, url?: string, payload?: any } = {
            resource: "settings",
            payload: { panorama: true }
        }
        if (process.env.REACT_APP_SHOW_CM === "true") {
            apiCall = {
                resource: "integrations",
            }
        }

        dataProvider.describe(apiCall.resource, apiCall.url || "", apiCall?.payload || {})
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                let links = Object.keys(response.data.Links);
                let cmLinks: Array<any> = [];
                let pnLinks: Array<any> = [];
                links.map(link => {
                    const value = response.data.Links[link].LinkName ? `${link} (${response.data.Links[link].LinkName})` : link;
                    if (response.data.Links[link]?.hasOwnProperty('CloudManager') && value !== undefined && response.data.Links[link].Status === 'Active') {
                        cmLinks.push({text: value, value: link});
                    }
                    if (response.data.Links[link]?.hasOwnProperty('Panorama') && value !== undefined) {
                        pnLinks.push({text: value, value: link});
                    }
                });
                setPanoramas(pnLinks);
                setCloudManagers(cmLinks);
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, {toastId: "support-describe"});
            })
    }, []);

    const getPolicyManagedList = () => {
        let PolicyManagedList = [
            { label: "Rulestack", value: "Rulestack" },
            { label: translate(`resources.firewalls.fields.Panorama`), value: "Panorama" }
        ];
        if (process.env.REACT_APP_SHOW_CM === "true") {
            PolicyManagedList.splice(1, 0, { label: translate(`resources.firewalls.fields.StrataCloudManager`), value: "Strata Cloud Manager" });
        }

        return PolicyManagedList;
    }

    const handlePolicyManaged = (selected: any, previous: any) => {
        if (previous === "Rulestack") {
            form.change("RuleStackName", undefined);
        }
        if (previous === "Strata Cloud Manager") {
            form.change("CMLinkId", undefined);
        }
        if (previous === "Panorama") {
            form.change("LinkId", undefined);
        }
    }

    return (
        <AccordionPanel title={translate(`resources.firewalls.fields.PolicyManagement`)}>
            <Row>
                <Grid item xs={12} sm={6} className="noPadding">
                    <FormControl fullWidth>
                        <Field
                            name="PolicyManaged"
                            title={"Managed By"}
                            row
                            // @ts-ignore
                            component={PANWDSRadioField}
                            options={getPolicyManagedList()}
                            validate={isRequired}
                            defaultValue={'Rulestack'}
                            //disabled={MultiVpcEnabled}
                            dataMetrics={"cloudngfw-firewall-create-policy-managed"}
                        />
                        <OnChange name={"PolicyManaged"}>
                            {(selected: any, previous) => handlePolicyManaged(selected, previous)}
                        </OnChange>
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.PolicyManaged === "Rulestack") {
                                return (
                                    <FormControl fullWidth>
                                        <Field
                                            name="RuleStackName"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.firewalls.fields.RuleStackName`)}
                                            items={ruleStackChoices || []}
                                            loading={ruleStackChoices === undefined}
                                            dataMetrics={"cloudngfw-firewall-create-rulestack-name"}
                                        />
                                    </FormControl>
                                );
                            } else return null;
                        }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.PolicyManaged === "Strata Cloud Manager") {
                                return (
                                    <FormControl fullWidth>
                                        <Field
                                            name="CMLinkId"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.firewalls.fields.PolicyManager`)}
                                            items={cloudManagers || []}
                                            loading={cloudManagers === undefined}
                                            dataMetrics={"cloudngfw-firewall-create-link-id"}
                                            muted={translate(`resources.firewalls.fields.LinkedCloudManagerMessage`)}
                                            allowSetNone
                                        />
                                    </FormControl>
                                );
                            } else return null;
                        }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => {
                            if (values.PolicyManaged === "Panorama") {
                                return (
                                    <FormControl fullWidth>
                                        <Field
                                            name="LinkId"
                                            row
                                            // @ts-ignore
                                            component={PANWDSSelectWithSearch}
                                            title={translate(`resources.firewalls.fields.LinkedPanorama`)}
                                            items={panoramas || []}
                                            loading={panoramas === undefined}
                                            dataMetrics={"cloudngfw-firewall-create-link-id"}
                                            muted={translate(`resources.firewalls.fields.LinkedPanoramaMessage`)}
                                            allowSetNone
                                        />
                                    </FormControl>
                                );
                            } else return null;
                        }}
                    </FormSpy>
                </Grid>
            </Row>
        </AccordionPanel>
    );
};

export default Index;
