import { FormControl } from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { PANWDSInput } from "../components/PANWDSElements";
import { Link, Button } from "@panwds/react-ui";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { Field, withTypes } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "../components";
import * as DataTypes from "../api/FwaasDataTypes";
import { dataProvider } from "../dataProvider";
import { authProvider, cognitoProvider, oktaProvider } from "../authProvider";
import { RouteUri } from "../routeUri";
import { ApplicationConfigManager } from "../types";
import { Background } from "./styles";
import { lightTheme } from "./themes";
import EventEmitter from "../eventEmitter";
import "./CustomPanwdsCard.css";
import { isBlockedDomain, isEmail, isRequired, composeValidators } from "../utils/validate";
import getUrlParameter from "../utils/GetUrlParameter";
import { useTranslate } from "../customHooks";
import WithTitle from "../routes/WithTitle";
import { AuthCustomCard } from "./components";
import { useOktaAuth } from "@okta/okta-react";
import { useAppDispatch } from "../app/hooks";
import { ReduxActions, ReduxResources } from "../redux";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  form: {
    paddingBottom: "1em",
  },
  formControl: {
    marginTop: "1em",
    width: "100%",
  },
  formControlNew: {
    marginTop: "1em",
    width: "100%",
    WebkitTextSecurity: "disc",
    textSecurity: "disc",
  },
  formControlAction: {
    justifyContent: "center"
  },
  pwd: {
    color: "#006FCC",
    cursor: "pointer",
    textAlign: "center",
  },
  linkContainer: {
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
  },
  link: {
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
  },
}));

interface FormValues {
  email?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

declare global {
  interface Window {
    pendo: any;
  }
}

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [cognitoLogin, setCognitoLogin] = useState(false);
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ nextPathname: string } | null>();
  const oktaAuth = useOktaAuth();
  const state = queryString.parse(window?.location?.search);

  useEffect(() => {
    const contractsMessage = <div>
      <div
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <h3>Your contract is ready! </h3>
      </div>
      <p>
        We noticed that you have not yet created a Cloud NGFW tenant to use
        these Cloud NGFW credits. You can create a Cloud NGFW tenant by
        following these steps:
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "10px auto",
          gap: "5px",
        }}
      >
        <div>1</div>
        <div>
          Navigate to the{" "}
          <a style={{ color: "white" }}
            href="https://aws.amazon.com/marketplace/pp/prodview-sdwivzp5q76f4"
            target="_blank"
          >
            Cloud NGFW Pay-As-You-Go
          </a>{" "}
          listing in the AWS Marketplace.
        </div>
        <div>2</div>
        <div>
          Sign in to your AWS account and click the orange “Continue to
          Subscribe” button.
        </div>
        <div>3</div>
        <div>
          Follow the{" "}
          <a style={{ color: "white" }}
            href="https://www.youtube.com/watch?v=qeyODhEBpj8&list=PLD6FJ8WNiIqWmfcE18dj7ItUiDECreaEc&index=19"
            target="_blank"
          >
            instructions{" "}
          </a>
          to create a Cloud NGFW tenant. Once you create the tenant, your
          purchased credits will be automatically applied.
        </div>
      </div>
    </div>;

    if (parseInt(getUrlParameter("status_code")) === 1000) {
      setTimeout(() => {
        toast.clear(contractsMessage, {
          autoDismiss: false
        });
      }, 1000);
    }
  }, []);

  const handleCSPWorkFlow = async (response: DataTypes.IFwaasApiResponse) => {
      try {
          let setCspWorkFlow = false;
          if (response.data) {
              if (response.data.SupportInformations && isEmpty(response.data.SupportInformations[0])) {
                  setCspWorkFlow = true;
              } else if (response.data && response.data.SupportInformations && !isEmpty(response.data.SupportInformations) && process.env.REACT_APP_SHOW_CSP === "true") {
                  const supportInfo = response.data.SupportInformations[0];
                  if (!supportInfo.SupportAccountId) {
                      setCspWorkFlow = true;
                  }
              }
          } else {
              throw response?.error;
          }
          let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
          newAppConfig.externalID = response?.data?.ExternalId;
          newAppConfig.setCspWorkFlow = setCspWorkFlow;
          await ApplicationConfigManager.getInstance().setConfig(newAppConfig);
          if (setCspWorkFlow) EventEmitter.emit("setCspWorkFlow", true);
      } catch (e: any) {
          toast.error(e?.error);
      }
  }

  const login = useCallback(
    (params: any = {}, pathName) =>
      cognitoProvider.login(params).then((ret) => {
        if (!ret?.state?.idToken) {
          history.replace(RouteUri.SetPassword);
        } else {
          // dataProvider
          //   .get("subscription")
          //   .then(async (response: DataTypes.IFwaasApiResponse) => {
          //     if (response.data) {
          //       if (response.data[0] && response.data[0].SubscriptionKey) {
          //         var newAppConfig =
          //           ApplicationConfigManager.getInstance().getConfig();
          //         newAppConfig.xApiKey = response?.data[0]?.SubscriptionKey;
          //         await ApplicationConfigManager.getInstance().setConfig(
          //           newAppConfig
          //         );
          //         ApplicationConfigManager.getInstance().pendoInitialize(
          //           newAppConfig.xApiKey
          //         );
          //       }
          //     } else {
          //       toast.error(response?.error?.error);
          //     }
          //   })
          //   .then(() => {
          //     dataProvider
          //       .describe("support")
          //       .then(async (response: DataTypes.IFwaasApiResponse) => {
          //         history.replace(RouteUri.Dashboard);
          //         if (response.data) {
          //           if (
          //             response.data.SupportInformations &&
          //             isEmpty(response.data.SupportInformations[0])
          //           ) {
          //             let newAppConfig =
          //               ApplicationConfigManager.getInstance().getConfig();
          //             newAppConfig.setCspWorkFlow = true;
          //             newAppConfig.externalID = response.data.ExternalId;
          //             await ApplicationConfigManager.getInstance().setConfig(
          //               newAppConfig
          //             );
          //             // @ts-ignore
          //             EventEmitter.emit("setCspWorkFlow");
          //           } else {
          //               let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
          //               newAppConfig.externalID = response.data.ExternalId;
          //               await ApplicationConfigManager.getInstance().setConfig(
          //                   newAppConfig
          //               );
          //           }
          //         } else {
          //           toast.error(response?.error?.error);
          //         }
          //       })
          //       .catch((e: any) => {
          //         toast.error(e?.error);
          //       });
          //   })
          //   .catch((e: any) => {
          //     toast.error(e?.error);
          //   });
            history.replace(RouteUri.Dashboard);
        }

        //dispatch(resetNotification());

        // const redirectUrl = pathName
        //     ? pathName
        //     : nextPathName + nextSearch ||
        //       defaultAuthParams.afterLoginUrl;
        return ret;
      }),
    []
  );

  const handleOktaLogin = async () => {
    const options = {
      state: state
    };
    await oktaProvider.signIn(options);
  }


  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    if (cognitoLogin) {
      cognitoProvider
        .signIn({...auth, state: state})
        .then((result: any) => {
          if (result?.redirectTo) {
            toast.error("Need to update the password");
          }

          if (window?.location?.search && queryString.parse(window?.location?.search)) {
            let tokens = result.getIdToken().getJwtToken()
            history.replace(RouteUri.Register, { queryString: state, tokens: tokens, idpC: true });
          }
          else {
            login(
              {
                pathName: "/passwordSet",
                state: result,
              },
              result && result.redirectTo
                ? result.redirectTo
                : location.state
                  ? location.state.nextPathname
                  : "/"
            ).catch((e: any) => toast.error(e?.error));
          }
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(
            typeof error === "string"
              ? error
              : typeof error === "undefined" || !error.message
                ? translate("ra.auth.sign_in_error")
                : error.message
          );
        });
    } else {
      authProvider.checkUser({ data: { UserName: encodeURIComponent(auth.email) } }).then((response) => {
        if (response.error) {
          throw response.error;
        } else {
          const existsInOkta = response.data.existsInOkta === "ACTIVE";
          const existsInCognito = response.data.existsInCognito === "YES";
          if ((existsInOkta && existsInCognito) || (!existsInOkta && existsInCognito)) {
            setLoading(false);
            setCognitoLogin(true);
          } else if (existsInOkta && !existsInCognito) {
            handleOktaLogin();
          } else {
            setLoading(false);
            toast.info("User not valid.");
          }
        }
      }).catch(err => {
        toast.error(err?.error);
        setLoading(false);
      })
    }
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("validation.required");
    }
    if (cognitoLogin && !values.password) {
      errors.password = translate("validation.required");
    }
    return errors;
  };

  return (
    <>
      {/* @ts-expect-error Server Component */}
      <Background>
        <AuthCustomCard
          loading={loading}
          title={translate(`login.logIn`)}>
          <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.form}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="email"
                      // @ts-ignore
                      component={PANWDSInput}
                      autoFocus={true}
                      title={translate(`login.email`)}
                      required
                      disabled={loading}
                      dataMetrics="cloudngfw-signin-email"
                      type="email"
                      validate={composeValidators(
                        isRequired,
                        isBlockedDomain,
                        isEmail
                      )}
                    />
                  </FormControl>

                  {cognitoLogin && <FormControl className={classes.formControl}>
                    <Field name="password">
                      {(props: any) => (
                        <PANWDSInput
                          meta={props.meta}
                          title={translate(`login.password`)}
                          type="password"
                          required
                          dataMetrics="cloudngfw-signin-password"
                          input={{
                            onFocus: props.input.onFocus,
                            onChange: props.input.onChange,
                            onBlur: props.input.onBlur,
                            type: "password"
                          }}
                        />
                      )}
                    </Field>
                  </FormControl>}
                  {cognitoLogin && <FormControl className={classes.linkContainer}>
                    <Link
                      data-metrics="cloudngfw-signin-forgot-password"
                      href={"/forgotPassword"}
                    >
                      {translate(`login.forgotPassword`)}
                    </Link>
                  </FormControl>}
                </div>
                <Button
                  appearance="primary"
                  size="lg"
                  addClassName={classes.formControlAction}
                  color="primary"
                  buttonPosition={"middle"}
                  disabled={loading}
                  onClick={handleSubmit}
                  fullWidth
                  data-metrics="cloudngfw-login-button"
                >
                  {translate(`login.logIn`)}
                </Button>
              </form>
            )}
          />
        </AuthCustomCard>
      </Background>
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <WithTitle title="Cloud NGFW Administration">
      <Login {...props} />
    </WithTitle>
  </ThemeProvider>
);

export default LoginWithTheme;

function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
