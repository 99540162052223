import {usePermissions, useTranslate} from "../../../../customHooks";
import {AccordionPanel, Body, Bold, Button, Tooltip} from "@panwds/react-ui";
import {Row} from "../../../../components/FormElements";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm, useFormState} from "react-final-form";
import {FieldArray} from "react-final-form-arrays";
import {
    PANWDSChipInput,
    PANWDSRadioField,
    PANWDSSelect,
    PANWDSSelectWithSearch,
    PANWDSCheckboxField
} from "../../../../components/PANWDSElements";
import {InfoIcon, PlusIcon, DeleteIcon} from "@panwds/icons";
import {isRequired} from "../../../../utils/validate";
import {OnChange} from "react-final-form-listeners";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {dataProvider} from "../../../../dataProvider";
import {toast} from "../../../../components";

const useStyles = makeStyles(() => ({
    iconedParagraph: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    deleteVpcComponent: {
        width: "32px",
        height: "40px",
    }
}));

const AccountIdComponent = ({accountChoices, handleAccountChange, translate, name, index}: any) => {
    return (
        <FormControl fullWidth>
            <Field
                name={name ? `${name}.AccountId` : 'AccountId'}
                // @ts-ignore
                component={PANWDSSelect}
                items={accountChoices}
                title={(index === 0 || index == undefined) && translate(`resources.firewalls.fields.AccountId`)}
                required
                validate={isRequired}
                loading={accountChoices === undefined}
                dataMetrics={"cloudngfw-firewall-create-account-id"}
                placeholder={'Select an account'}
            />
            <OnChange name={name ? `${name}.AccountId` : 'AccountId'}>
                {(selected: any) => handleAccountChange(selected, '')}
            </OnChange>
        </FormControl>
    );
};

const DefaultVpcComponent = (props: any) => {
    const {
        accountChoices,
        accountsData,
        handleAccountChange,
        handleVpcInformationChange,
        vpcSubnetData,
        updatingVPC,
        updatingSubnet,
        translate,
        classes,
    } = props;

    return (
        <FieldArray name="ServiceManaged">
            {({fields}) => (
                <>
                    {fields.map((name, index) => {
                        return (
                            <div className="tw-flex tw-gap-2 tw-pr-4" key={name}>
                                <Grid item xs={12} sm={3} className="bottomPadding">
                                    {AccountIdComponent({accountChoices, handleAccountChange, translate, name, index})}
                                </Grid>
                                <Grid item xs={12} className="bottomPadding">
                                    <FormSpy subscription={{ values: true }}>
                                        {({values}) => {
                                            const accountId = get(values, `${name}.AccountId`);
                                            const vpcInformationChoices = accountsData[accountId]?.vpcInformation || [];
                                            return (
                                                <FormControl fullWidth>
                                                    <Field
                                                        name={`${name}.VpcId`}
                                                        // @ts-ignore
                                                        component={PANWDSSelectWithSearch}
                                                        items={vpcInformationChoices}
                                                        title={index === 0 && translate(`resources.firewalls.fields.VpcId`)}
                                                        required
                                                        validate={isRequired}
                                                        loading={updatingVPC[accountId]}
                                                        dataMetrics={"cloudngfw-firewall-create-vpc"}
                                                        disabled={!accountId}
                                                    />
                                                    <OnChange name={`${name}.VpcId`}>
                                                        {(selected: any, previous: any) => handleVpcInformationChange({
                                                            name,
                                                            vpcId: selected,
                                                            accountId,
                                                            previous,
                                                            formValues: values
                                                        })}
                                                    </OnChange>
                                                </FormControl>
                                            )
                                        }}
                                    </FormSpy>
                                </Grid>
                                <Grid item xs={12} className="bottomPadding">
                                    <FormSpy subscription={{ values: true }}>
                                        {({values}) => {
                                            const vpcId = get(values, `${name}.VpcId`)
                                            const subnetChoices = vpcSubnetData[vpcId] || [];
                                            return (
                                                <FormControl fullWidth>
                                                    <Field
                                                        name={`${name}.Subnet`}
                                                        row
                                                        // @ts-ignore
                                                        component={PANWDSChipInput}
                                                        title={index === 0 && translate(`resources.firewalls.fields.Subnet`)}
                                                        items={(subnetChoices || []).map((sc: any) => ({ id: sc.id, value: sc.name }))}
                                                        loading={updatingSubnet[vpcId]}
                                                        dataMetrics={"cloudngfw-firewall-create-subnet"}
                                                        required
                                                        disabled={!vpcId}
                                                        menuStyle={{width: '27rem'}}
                                                    />
                                                </FormControl>
                                            );
                                        }}
                                    </FormSpy>
                                </Grid>
                                <Grid item>
                                    <div className={index === 0 ? classes.deleteVpcComponent : ""}>
                                        {index !== 0 && <Button onClick={() => fields.remove(index)}
                                                                aria-label="Icon only button"
                                                                icon={<DeleteIcon size="md"/>}
                                                                appearance={"tertiary"}
                                        />}
                                    </div>
                                </Grid>
                            </div>
                        );
                    })}
                    <Button icon={<PlusIcon size="md" />}
                            appearance={"secondary"}
                            onClick={() => fields.push({AccountId: '', Subnet: '', VpcId: ''})}
                    >Add</Button>
                </>
            )}
        </FieldArray>
    );
};

const Index = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const { rawPermissions } = usePermissions();
    const form = useForm();
    const formState = useFormState();

    //Accounts Details
    const [accountChoices, setAccountChoices] = useState<any[] | undefined>(undefined);
    const [accountsData, setAccountsData] = useState<any>({});

    // VPC and Availability Zones Details
    const [updatingVPC, setUpdatingVPC] = useState<any>({});

    // Subnet Details
    const [updatingSubnet, setUpdatingSubnet] = useState<any>({});
    const [vpcSubnetData, setVpcSubnetData] = useState<any>({});

    const [isSubnet, setIsSubnet] = useState(false);

    useEffect(() => {
        if (!isEmpty(rawPermissions)) {
            let choices = rawPermissions.filter((element: any) => element?.Policy === "LocalFirewallAdmin")
                .map((permission: any) =>
                    ({text: permission?.AccountId, value: permission?.AccountId})
                );
            setAccountChoices(choices);
        }
    }, []);

    const describeAccount = ({accountId, vpcId}: any) => {
        let payload = {
            AccountId: accountId,
            DescribeAccount: true, VpcId: undefined

        }
        if (vpcId) {
            payload.VpcId = vpcId;
        }
        try {
            return dataProvider.describe("xaccountroles", '', payload)
                .then((response: any) => {
                    if (response.data) {
                        return response.data;
                    } else {
                        throw response?.error?.error;
                    }
                })
                .catch((e: any) => {
                    throw e?.error?.error;
                });
        } catch (e: any) {
            toast.error(e, { toastId: "xaccountroles-describe" });
        }
    }

    const handleAccountChange = (currentValue: string, previousValue: string) => {
        setUpdatingVPC({...updatingVPC, [currentValue]: true});
        let currentAccountData = accountsData[currentValue];
        if (isEmpty(currentAccountData) && currentValue) {
            describeAccount({accountId: currentValue})
                .then((response: any) => {
                    let accountData = {
                        vpcInformation: [],
                        availabilityZones: []
                    }
                    if (response.VpcInformations) {
                        let vpcData = response.VpcInformations;
                        accountData.vpcInformation = vpcData.map((vpc: any) => ({ disabled: false, text: (vpc['VpcName']) ? `${vpc['VpcId']} (${vpc['VpcName']})` : vpc['VpcId'], value: vpc['VpcId']}));

                    }
                    if (response.AvailabilityZones) {
                        let availabilityZoneData = response.AvailabilityZones;
                        let availabilityZoneIds = response?.AvailabilityZoneIds;
                        //accountData.availabilityZones = availabilityZoneData.map((zone: any, index: number) => ({ id: zone, name: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone }));
                        accountData.availabilityZones = availabilityZoneData.map((zone: any, index: number) => ({ value: zone, text: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone }));
                    }
                    setAccountsData({...accountsData, [currentValue]: accountData});
                    setUpdatingVPC({...updatingVPC, [currentValue]: false});
                });
        } else {
            setUpdatingVPC({...updatingVPC, [currentValue]: false});
        }
    };

    type handleVpcInformationChangeProps = {
        vpcId: string,
        accountId: string,
        previous: any,
        name?: string,
        formValues?: any
    }

    const onFieldChange = (props: handleVpcInformationChangeProps) => {
        const {
            vpcId,
            previous,
            name,
        } = props;
        if (vpcId !== previous) {
            /*TODO: use this on phase 2 of egress Nat
            changeFormField({fieldName: `${name}.Subnet`});*/
            changeFormField({fieldName: `Subnet`});
        }
        changeVpcData(props);
    }

    const handleVpcInformationChange = (props: handleVpcInformationChangeProps) => {
        const { vpcId, accountId } = props;
        onFieldChange(props);
        setUpdatingSubnet({...updatingSubnet, [vpcId]: true});
        let subnets = vpcSubnetData[vpcId];
        if (isEmpty(subnets) && accountId) {
            describeAccount({accountId, vpcId})
                .then((response: any) => {
                    if (response.VpcInformations) {
                        let vpcData = response.VpcInformations;
                       //let vpcSData = vpcData[0].SubnetInformations.map((subnet: any) => ({ id: subnet['SubnetId'], name: (subnet['SubnetName']) ? `${subnet['SubnetId']} (${subnet['SubnetName']})` : subnet['SubnetId'] }));
                       let vpcSData = vpcData[0].SubnetInformations.map((subnet: any) => ({ value: subnet['SubnetId'], text: (subnet['SubnetName']) ? `${subnet['SubnetId']} (${subnet['SubnetName']})` : subnet['SubnetId'] }));
                        setVpcSubnetData(({ ...vpcSubnetData, [vpcId]: vpcSData }));
                        setUpdatingSubnet({...updatingSubnet, [vpcId]: false});
                    }
                });
        } else {
            if (!accountId) {
                toast.info(translate('resources.firewalls.create.selectAccount'));
            }
            setUpdatingSubnet({...updatingSubnet, [vpcId]: false});
        }
    };

    const changeFormField = ({fieldName, value = undefined}: {fieldName: string, value?: any}) => {
        form.change(fieldName, value);
    }

    const changeVpcData = ({accountId, vpcId, previous}: handleVpcInformationChangeProps) => {
        let currentVpcData = accountsData[accountId].vpcInformation.map((vpc: any) => {
            if (vpc.value === vpcId) {
                vpc.disabled = true;
            }
            if (vpc.value === previous) {
                vpc.disabled = false;
            }
            return vpc;
        });
        setAccountsData({...accountsData, [accountId]: {...accountsData[accountId], vpcInformation: currentVpcData}});
    }

    const handleEndpointMode = (selected: any) => {
        /*TODO: uncomment this on phase 2
        if (selected === "ServiceManaged") {
            form.mutators.push("ServiceManaged", {AccountId: '', Subnet: '', VpcId: ''})
            changeFormField({fieldName: "AccountId"});
            changeFormField({fieldName: "AvailabilityZone"});
        } else {
            form.reset({...formState.values, ServiceManaged: undefined});
            Object.keys(accountsData).map((accountId) => {
                let currentVpcData = accountsData[accountId].vpcInformation.map((vpc: any) => {
                    vpc.disabled = false;
                    return vpc;
                });
                setAccountsData({...accountsData, [accountId]: {...accountsData[accountId], vpcInformation: currentVpcData}});
            })
        }*/
        // TODO: Remove this for phase 2
        if (selected === "ServiceManaged") {
            changeFormField({fieldName: "AvailabilityZone"});
        } else {
            changeFormField({fieldName: "Subnet"});
        }
        setIsSubnet((selected === "ServiceManaged"));
    }

    const WhenFieldChanges = ({field, becomes, set, to}: any) => (
        <Field name={set} subscription={{}}>
            {(
                {input: {onChange}}
            ) => (
                <FormSpy subscription={{}}>
                    {({form}) => (
                        <OnChange name={field}>
                            {value => {
                                if (value === becomes) {
                                    onChange(to);
                                }
                            }}
                        </OnChange>
                    )}
                </FormSpy>
            )}
        </Field>
    );

    return (
        <AccordionPanel title={translate(`resources.firewalls.fields.EndpointManagement`)}
                        caption={translate(`resources.firewalls.fields.EndpointManagementSubtitle`)}>
            {/*<WhenFieldChanges*/}
            {/*    field="MultiVpcEnable"*/}
            {/*    becomes={true}*/}
            {/*    set="EndpointMode"*/}
            {/*    to={"CustomerManaged"}*/}
            {/*/>*/}
            <Row>
                <Grid item sm={12} className="noPadding" style={{paddingBottom: "1rem"}}>
                    <FormControl>
                        <Field
                            name="MultiVpcEnable"
                            // @ts-ignore
                            component={PANWDSCheckboxField}
                            defaultValue={true}
                            label={<div className={classes.iconedParagraph}>{translate(`resources.firewalls.fields.Shared`)}
                                <Tooltip label={translate(`resources.firewalls.fields.SharedTooltip`)}>
                                    {/*@ts-ignore*/}
                                    <InfoIcon style={{ paddingLeft: "10px" }} size="xs" />
                                </Tooltip></div>}
                            type="checkbox"
                            dataMetrics={"cloudngfw-firewall-edit-multivpcenabled"}
                        />
                        <OnChange name="MultiVpcEnable">
                            {(value: any) => value && changeFormField({fieldName: "EndpointMode", value: "CustomerManaged"})}
                        </OnChange>
                    </FormControl>
                </Grid>
            </Row>

            <Row>
                <Grid item xs={12} sm={6} className="noPadding">
                    <FormSpy subscription={{ values: true }}>
                        {props => {
                            let MultiVpcEnabled = props?.values?.MultiVpcEnable;
                            return (
                                <FormControl fullWidth>
                                    <Field
                                        name="EndpointMode"
                                        row
                                        // @ts-ignore
                                        component={PANWDSRadioField}
                                        initValue={MultiVpcEnabled ? 'CustomerManaged' : 'ServiceManaged'}
                                        options={[
                                            { label: "Yes", value: "ServiceManaged" },
                                            { label: "No", value: "CustomerManaged" }
                                        ]}
                                        title={
                                            <div className={classes.iconedParagraph}>
                                                {translate(`resources.firewalls.fields.SubnetSubtitle`)}
                                                {/*<Tooltip label={translate(`resources.firewalls.fields.SharedTooltip`)}>*/}
                                                {/*    <InfoIcon size="xs" />*/}
                                                {/*</Tooltip>*/}
                                            </div>
                                        }
                                        required
                                        validate={isRequired}
                                        defaultValue={MultiVpcEnabled ? 'CustomerManaged' : 'ServiceManaged'}
                                        disabled={MultiVpcEnabled}
                                        dataMetrics={"cloudngfw-firewall-create-endpoint-mode"}
                                    />
                                    <OnChange name="EndpointMode">
                                        {(selected: any) => handleEndpointMode(selected)}
                                    </OnChange>
                                </FormControl>)
                        }}
                    </FormSpy>
                </Grid>
            </Row>

                <>
                    <Row>
                        <Grid item xs={12} sm={6} className="bottomPadding">
                            {AccountIdComponent({accountChoices, handleAccountChange, translate})}
                        </Grid>
                    </Row>

                    {/*//TODO: Below VpcId code need to remove before pushing this changes because for now without VPC cant create firewall*/}
                    <Row>
                        <Grid item xs={12} sm={6} className="bottomPadding">
                            <FormSpy subscription={{ values: true }}>
                                {({values}) => {
                                    const vpcInformationChoices = accountsData[values[`AccountId`]]?.vpcInformation || [];
                                    return (
                                        <FormControl fullWidth>
                                            <Field
                                                name={`VpcId`}
                                                // @ts-ignore
                                                component={PANWDSSelectWithSearch}
                                                items={vpcInformationChoices}
                                                title={translate(`resources.firewalls.fields.VpcId`)}
                                                required
                                                validate={isRequired}
                                                loading={updatingVPC[values[`AccountId`]]}
                                                dataMetrics={"cloudngfw-firewall-create-vpc"}
                                                disabled={!values['AccountId']}
                                            />
                                            <OnChange name={`VpcId`}>
                                                {(selected: any, previous: any) => handleVpcInformationChange({
                                                    vpcId: selected,
                                                    accountId: values['AccountId'],
                                                    previous,
                                                    formValues: values
                                                })}
                                            </OnChange>
                                        </FormControl>
                                    )
                                }}
                            </FormSpy>
                        </Grid>
                    </Row>

                    {!isSubnet && (<Row>
                        <Grid item xs={12} sm={6} className="bottomPadding">
                            <FormSpy subscription={{ values: true }}>
                                {({values}) => {
                                    const availabilityZoneChoices = accountsData[values[`AccountId`]]?.availabilityZones || [];
                                    return (
                                        <FormControl fullWidth>
                                            {/* <Field
                                                name="AvailabilityZone"
                                                row
                                                // @ts-ignore
                                                component={PANWDSChipInput}
                                                title={translate(`resources.firewalls.fields.AvailabilityZone`)}
                                                items={(availabilityZoneChoices || []).map((az: any) => ({
                                                    id: az.id,
                                                    value: az.name
                                                }))}
                                                loading={updatingVPC[values[`AccountId`]]}
                                                dataMetrics={"cloudngfw-firewall-create-availability-zone"}
                                                disabled={!values[`AccountId`]}
                                                required
                                                muted={"You can select multiple Availability Zone IDs"}
                                                menuStyle={{width: '12rem'}}
                                            /> */}
                                            <Field
                                                name="AvailabilityZone"
                                                row
                                                // @ts-ignore
                                                component={PANWDSSelectWithSearch}
                                                title={translate(`resources.firewalls.fields.AvailabilityZone`)}
                                                items={availabilityZoneChoices}
                                                loading={updatingVPC[values[`AccountId`]]}
                                                enableArrayInput
                                                enableMultiSelect
                                                dataMetrics={"cloudngfw-firewall-create-availability-zone"}
                                                disabled={!values[`AccountId`]}
                                                required
                                                muted={"You can select multiple Availability Zone IDs"}
                                                />
                                        </FormControl>
                                    );
                                }}
                            </FormSpy>
                        </Grid>
                    </Row>)}
                    {isSubnet && <Grid item xs={12} sm={6} className="bottomPadding">
                        <FormSpy subscription={{ values: true }}>
                            {({values}) => {
                                const subnetChoices = vpcSubnetData[values[`VpcId`]] || [];
                                return (
                                    <FormControl fullWidth>
                                        {/* <Field
                                            name={`Subnet`}
                                            row
                                            // @ts-ignore
                                            component={PANWDSChipInput}
                                            title={translate(`resources.firewalls.fields.Subnet`)}
                                            items={(subnetChoices || []).map((sc: any) => ({ id: sc.id, value: sc.name }))}
                                            loading={updatingSubnet[values[`VpcId`]]}
                                            dataMetrics={"cloudngfw-firewall-create-subnet"}
                                            required
                                            disabled={!values[`VpcId`]}
                                            menuStyle={{width: '27rem'}}
                                        /> */}
                                        <Field
                                                name="Subnet"
                                                row
                                                // @ts-ignore
                                                component={PANWDSSelectWithSearch}
                                                title={translate(`resources.firewalls.fields.Subnet`)}
                                                items={subnetChoices}
                                                loading={updatingSubnet[values[`VpcId`]]}
                                                enableArrayInput
                                                enableMultiSelect
                                                dataMetrics={"cloudngfw-firewall-create-subnet"}
                                                disabled={!values[`VpcId`]}
                                                required
                                                />
                                    </FormControl>
                                );
                            }}
                        </FormSpy>
                    </Grid>}
                </>

            {/*//TODO: uncomment this on phase 2*/}
            {/*{isSubnet &&*/}
            {/*    <>*/}
            {/*        <Body size={"sm"} addClassName={"tw-pb-2"}>*/}
            {/*            <Bold appearance={"semibold"}>*/}
            {/*                Select VPC and Subnet ID. You can choose multiple Subnet IDs, and the system will create an endpoint for each one.*/}
            {/*            </Bold>*/}
            {/*        </Body>*/}
            {/*        {DefaultVpcComponent({*/}
            {/*            translate,*/}
            {/*            accountChoices,*/}
            {/*            accountsData,*/}
            {/*            vpcSubnetData,*/}
            {/*            updatingVPC,*/}
            {/*            updatingSubnet,*/}
            {/*            classes,*/}
            {/*            handleAccountChange,*/}
            {/*            handleVpcInformationChange,*/}
            {/*        })}*/}
            {/*    </>*/}
            {/*}*/}
        </AccordionPanel>
    );
};

export default Index;
