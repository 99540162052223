import {AccordionPanel} from "@panwds/react-ui";
import {usePermissions, useTranslate} from "../../../../customHooks";
import {Field, useFormState} from "react-final-form";
import {PANWDSInput} from "../../../../components/PANWDSElements";
import {FormControl, Grid} from "@material-ui/core";
import * as React from "react";
import {PANChip, Row} from "../../../../components/FormElements";

interface GeneralProps {
    record: any,
}

const Index = (props: GeneralProps) => {
    const { record } = props;
    const translate = useTranslate();
    const formState = useFormState();
    const { permissions } = usePermissions();
    return (
        <AccordionPanel title={translate(`resources.firewalls.fields.General`)}>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.FirewallName"
                            readOnly
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.firewalls.fields.FirewallName`)}
                            dataMetrics={"cloudngfw-firewall-edit-name"}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.Description"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.firewalls.fields.Description`)}
                            disabled={!permissions?.UpdateFirewallDescription || formState?.values?.FirewallUpdating}
                            dataMetrics={"cloudngfw-firewall-edit-description"}
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Firewall.Tags"
                            // @ts-ignore
                            component={PANChip}
                            options={[]}
                            addNew
                            addNewLabel="Add new"
                            label={translate(`resources.firewalls.fields.Tags`)}
                            addDirectly
                            disabled={!permissions?.UnTagFirewall || formState?.values?.FirewallUpdating}
                            disableButton={!permissions?.TagFirewall || formState?.values?.FirewallUpdating}
                        />
                    </FormControl>
                </Grid>
            </Row>
        </AccordionPanel>
    );
};

export default Index;
