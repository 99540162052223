import {AccordionPanel, Link} from "@panwds/react-ui";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm, useFormState} from "react-final-form";
import {PANWDSRadioField, PANWDSSelectWithSearch} from "../../../../components/PANWDSElements";
import * as React from "react";
import {useTranslate} from "../../../../customHooks";
import {useEffect, useState} from "react";
import {dataProvider} from "../../../../dataProvider";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import {toast} from "../../../../components";
import {useLocation} from "react-router-dom";
import CircularLoader from "../../../../components/CircularLoader/CircularLoader";
import { FormattedMessage } from "react-intl";
import {isEmpty} from "lodash";

const Index = ({record, PolicyManagementState}: {record: any, PolicyManagementState: any}) => {
    const translate = useTranslate();
    const formState = useFormState();
    const form = useForm();
    const search = useLocation().search;
    const accountId = new URLSearchParams(search).get('AccountId');
    const [ruleStackChoices, setRuleStackChoices] = useState<any[] | undefined>(undefined);
    const [panoramas, setPanoramas] = useState<any[] | undefined>(undefined);
    const [cloudManagers, setCloudManagers] = useState<any[] | undefined>(undefined);
    const [integrationType, setIntegrationType] = useState('Rulestack');
    const [scmNoLink, setScmNoLink] = useState(false);

    useEffect(() => {

        dataProvider.get("ruleStacks", {
            data: {
                Candidate: false,
                Running: true,
                Scope: "Local",
                AccountId: accountId
            }
        }).then(async (response: DataTypes.IFwaasApiResponse) => {
            //@ts-ignore
            let ret: DataTypes.IFwaasApiResponse = {};
            if (response?.data) {
                //@ts-ignore
                let rulestacks = response.data as string[];
                if (rulestacks) {
                    let choices: any = rulestacks.map((rulestack: any) => ({text: rulestack, value: rulestack}));
                    choices = choices.sort((first: any, second: any) => {
                        return first.value.localeCompare(second.value, undefined, {sensitivity: 'base'});
                    });

                    setRuleStackChoices(choices);
                    return;
                }
            } else {
                toast.error(response?.error, {toastId: "ruleStacks-get"});
            }
            return ret;
        }).catch((e: any) => {
            toast.error(e?.error, {toastId: "ruleStacks-get"});
        });
    }, [record]);

    useEffect(() => {
        if (!isEmpty(PolicyManagementState)) {
            const {pnLinks, cmLinks, iType} = PolicyManagementState;
            setPanoramas(pnLinks);
            setCloudManagers(cmLinks);
            setIntegrationType(iType);
        }
    }, [PolicyManagementState]);

    const getPolicyManagedList = () => {
        let PolicyManagedList = [
            {label: "Rulestack", value: "Rulestack"},
            {label: translate(`resources.firewalls.fields.Panorama`), value: "Panorama"}
        ];
        if (process.env.REACT_APP_SHOW_CM === "true") {
            PolicyManagedList.splice(1, 0, {
                label: translate(`resources.firewalls.fields.StrataCloudManager`),
                value: "Strata",
                disabled: !record?.Firewall?.LinkId && !record?.Firewall?.RuleStackName
            });
        }

        return PolicyManagedList;
    }

    return (
        <AccordionPanel title={translate(`resources.firewalls.fields.PolicyManagement`)}>
            <FormControl fullWidth>
                <Field
                    name="PolicyManaged"
                    row
                    // @ts-ignore
                    component={PANWDSRadioField}
                    options={getPolicyManagedList()}
                    initValue={integrationType}
                    disabled={record?.Firewall?.LinkId || record?.Firewall?.RuleStackName || record?.Firewall?.CMLinkId || formState?.values?.FirewallUpdating }
                    dataMetrics={"cloudngfw-firewall-edit-policy-managed"}
                    muted={(formState.values?.Firewall?.LinkId) ? translate(`resources.firewalls.fields.PolicyManagementLinkIdMessage`) :
                        (formState.values?.Firewall?.RuleStackName) ? translate(`resources.firewalls.fields.PolicyManagementRSMessage`) : formState.values?.Firewall?.CMLinkId ?  <FormattedMessage
                            id={"resources.firewalls.fields.PolicyManagementSCMMessage"}
                            values={{
                                break: <br />,
                                link: <Link
                                    dataMetrics="support-request"
                                    external
                                    href="https://support.qa.app.paloaltonetworks.com/Support/Index"
                                >
                                    {translate(`resources.firewalls.fields.CreateANewCase`)}
                                </Link>
                            }} />: ""}
                />
            </FormControl>
            <FormSpy subscription={{values: true}}>
                {({values, ...rest}) => {
                    if (values.PolicyManaged === "Rulestack") {
                        return (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="Firewall.RuleStackName"
                                        row
                                        // @ts-ignore
                                        component={PANWDSSelectWithSearch}
                                        title={translate(`resources.firewalls.fields.RuleStackName`)}
                                        items={ruleStackChoices || []}
                                        dataMetrics={"cloudngfw-firewall-edit-rulestack-name"}
                                        disabled={formState?.values?.FirewallUpdating}
                                        allowSetNone={record?.Firewall?.RuleStackName}
                                    />
                                </FormControl>
                            </Grid>
                        );
                    } else if (values?.PolicyManaged === "Strata") {
                        return (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="Firewall.CMLinkId"
                                        row
                                        // @ts-ignore
                                        component={PANWDSSelectWithSearch}
                                        title={translate(`resources.firewalls.fields.PolicyManager`)}
                                        items={cloudManagers || []}
                                        loading={cloudManagers === undefined}
                                        disabled={cloudManagers === undefined || values?.Firewall?.GlobalRuleStackName || formState?.values?.FirewallUpdating}
                                        dataMetrics={"cloudngfw-firewall-edit-link-id"}
                                        muted={translate(`resources.firewalls.fields.LinkedCloudManagerMessage`)}
                                        allowSetNone
                                    />
                                </FormControl>
                            </Grid>
                        );
                    } else if (values.PolicyManaged === "Panorama") {
                        return (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="Firewall.LinkId"
                                        row
                                        // @ts-ignore
                                        component={PANWDSSelectWithSearch}
                                        title={translate(`resources.firewalls.fields.LinkedPanorama`)}
                                        items={panoramas || []}
                                        loading={panoramas === undefined}
                                        disabled={panoramas === undefined || values?.Firewall?.GlobalRuleStackName || formState?.values?.FirewallUpdating}
                                        dataMetrics={"cloudngfw-firewall-create-link-id"}
                                        muted={(values?.Firewall?.GlobalRuleStackName) ? translate(`resources.firewalls.fields.LinkedPanoramaMessage`) : ""}
                                        allowSetNone
                                    />
                                </FormControl>
                            </Grid>
                        );
                    } else return null;
                }}
            </FormSpy>
        </AccordionPanel>
    );
};

export default Index;
